@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.registration-form-container {
  background-color: #f6f6e9;
  padding: 60px 40px;
  margin: 60px auto;
  max-width: 800px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.registration-title {
  text-align: center;
  color: #0d243d;
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
}

.registration-form .form-group {
  margin-bottom: 30px;
}

.registration-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #0d243d;
}

.registration-form input[type="text"],
.registration-form input[type="email"],
.registration-form input[type="tel"],
.registration-form input[type="age"],
.registration-form select,
.registration-form input[type="file"] {
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.registration-form input[type="text"]:focus,
.registration-form input[type="email"]:focus,
.registration-form input[type="tel"]:focus,
.registration-form input[type="age"],
.registration-form select:focus,
.registration-form input[type="file"]:focus {
  border-color: #00afef;
  box-shadow: 0 0 8px rgba(0, 175, 239, 0.2);
}

.qr-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #00afef;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.qr-button:hover {
  background-color: #008bcc;
}

.qr-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-popup-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
}

.qr-popup-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #0d243d;
}

.qr-popup h5 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #0d243d;
  font-family: 'Playfair Display', serif;
}

.qr-image {
  width: 200px;
  height: 200px;
}

.submit-button {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  color: white;
  background-color: #0d243d;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.submit-button:hover {
  background-color: #0a1c2e;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #0d243d;
}

.responsive-table th,
.responsive-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.responsive-table th {
  background-color: #00afef;
  color: white;
}

@media (max-width: 768px) {
  .qr-popup-content {
    padding: 20px;
  }

  .qr-image {
    width: 150px;
    height: 150px;
  }

  .submit-button {
    padding: 12px;
    font-size: 1rem;
  }

  .responsive-table th,
  .responsive-table td {
    padding: 8px;
  }
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #0d243d;
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
  display: block; /* Make table scrollable */
}

.responsive-table th,
.responsive-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
}

.responsive-table th {
  background-color: #00afef;
  color: white;
}

@media (max-width: 768px) {
  .responsive-table th,
  .responsive-table td {
    padding: 8px;
    font-size: 0.8rem;
  }
}

/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

