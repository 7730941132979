@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.home {
  background-color: #f6f6e9;
  padding: 50px 20px;
  min-height: 100vh;
}

.logos {
  margin-bottom: 30px;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #0d243d;
  margin-bottom: 10px;
}

.presents-text {
  font-size: 1.5rem;
  color: #333;
  margin: 20px 0;
  font-family: 'Roboto', sans-serif;
}

.nescon-logo {
  max-width: 70%;
  margin: 5px 0;
  opacity: 0;
  transform: translateY(20px);
  animation: logoFadeIn 1.5s ease-out forwards;
}

.description {
  font-size: 1.2rem;
  color: #333;
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

@keyframes logoFadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 2.2rem;
  }

  .presents-text {
    font-size: 1.3rem;
  }

  .description {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .presents-text {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.8rem;
  }

  .presents-text {
    font-size: 1rem;
  }

  .description {
    font-size: 0.85rem;
  }
}

.home-button .btn.btn-primary.register-btn {
  width: 250px;
  font-size: 1.2rem;
  background: linear-gradient(135deg, #0d243d, #00afef);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background 0.3s ease;
}

.home-button .btn.btn-primary.register-btn:hover {
  background: linear-gradient(135deg, #00afef,#0d243d);
  color: #ffffff;
}

/* Styling for the three logos at the top */
.logo-row {
  margin-bottom: 30px;
}

.top-logo {
  /* max-width: 100px; */
  width: 120px;
  height: 800px;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .top-logo {
    max-width: 80px;
  }
}

@media (max-width: 480px) {
  .top-logo {
    max-width: 60px;
  }
}

.flash-message {
  background: linear-gradient(135deg, #0d243d, #00afef);
  color: red;
  padding: 10px;
  font-size: 1.2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  overflow: hidden;
  position: relative;
  height: 40px;
  margin-top: 20px;
}

.moving-text {
  position: absolute;
  white-space: nowrap;
  animation: move-text 12s linear infinite;
  right: 0;
}

@keyframes move-text {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-400%);
  }
}

@media (max-width: 768px) {
.moving-text {
  position: absolute;
  white-space: nowrap;
  animation: move-text 12s linear infinite;
  right: 0;
}
  @keyframes move-text {
  0% {
    transform: translateX(80%);
  }
  100% {
    transform: translateX(-100%);
  }
}
}