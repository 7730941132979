.organizing-committee {
  background-color: #f6f6e9;
  padding: 50px 20px;
  border-radius: 10px;
  margin: 60px auto;
}

.committee-title {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  color: #0d243d;
  margin-bottom: 30px;
}

.committee-role {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #00afef;
  padding-bottom: 10px;
}

.white-text {
    color: #fff;
}

.member-card, .subcommittee-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(135deg,#0d243d, #00afef);
  transition: box-shadow 0.3s ease;
}

.member-card:hover, .subcommittee-card:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.member-info {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 10px;
}

.contact-note {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  color: #333;
  margin-top: 20px;
}

.contact-note a {
  color: #00afef;
  text-decoration: underline;
}

