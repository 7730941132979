.gallery-container {
  padding: 20px;
  background-color: #f6f6e9;
  border-radius: 15px;
  margin: 60px auto;
}

.gallery-title {
  text-align: center;
  font-family: 'Playfair Display', serif;
  color: #0d243d;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.my-masonry-grid {
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

.gallery-item {
  margin-bottom: 15px; /* gutter size */
}

.gallery-image-wrapper {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery-image-wrapper:hover {
  transform: translateY(-5px); /* Add hover effect */
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

.view-more-btn {
  background: linear-gradient(135deg, #0d243d, #00afef);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.view-more-btn:hover {
  background: linear-gradient(135deg, #00afef, #0d243d);
}

/* Swiper Styles */
.swiper {
  padding-top: 50px;
  padding-bottom: 80px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0006); */
}