.about-container {
  background: linear-gradient(135deg, #0d243d, #00afef);
  padding: 60px 40px;
  margin: 60px auto;
  max-width: 800px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

.about-title {
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
}

.about-carousel-caption {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1.5rem;
  font-family: 'Playfair Display', serif;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.about-paragraph {
  padding-top: 35px;
  font-size: 1.2rem;
  color: #ffffff;
  line-height: 1.6;
  text-align: justify;
}

@media (max-width: 768px) {
  .about-title {
    font-size: 2rem;
  }

  .about-paragraph {
    font-size: 1rem;
  }

  .about-carousel-caption {
    font-size: 1.2rem;
  }
}
