@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.speakers-container {
    background-color: #f6f6e9;
    padding: 60px 20px;
    text-align: center;
}

.speakers-title {
    font-family: 'Playfair Display', serif;
    font-size: 2.5rem;
    color: #0d243d;
    margin-bottom: 50px;
}

.speakers-carousel .carousel-item-content {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.speakers-carousel .carousel-item-content:hover {
    transform: scale(1.05);
}

.carousel-image {
background: linear-gradient(135deg, #0d243d, #00afef);
    border-radius: 10px;
    height: auto;
    max-height: 500px; /* Ensures the image doesn't become excessively large on bigger screens */
    width: 100%;
    object-fit: contain; /* Prevents cropping */
    object-position: center; /* Centers the image within the frame */
}


.carousel-caption{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    background: #f6f6e9;
    color: #0d243d;
    padding: 10px 20px;
    border-radius: 0 0 10px 10px;
    font-family: 'Roboto', sans-serif;
}

.carousel-caption h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #0d243d;
}

.carousel-caption p {
    margin: 0;
    font-size: 1rem;
    color: #0d243d;
}

@media (min-width: 768px) {
    .carousel-image {
        height: 400px;
    }
    
}

@media (min-width: 1024px) {
    .carousel-image {
        height: 500px;
    }
}

@media (max-width:500px) {
.carousel-caption h3 {
        font-size: 1rem;
        margin: 0;
    }

    .carousel-caption p {
        margin: 0;
        font-size: 1rem;
    }
    
}