.custom-navbar {
  background: linear-gradient(135deg, #0d243d, #00afef);
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  max-width: 50px;
  height: auto;
  border-radius: 50%;
  transition: transform 0.3s ease;
  background-color: white;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-logo:hover {
  transform: scale(1.1);
}

.navbar-nav .nav-link {
  color: white !important;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #f6f6e9 !important;
  transform: translateY(-2px);
}

.navbar-nav .nav-link.active {
  border-bottom: 2px solid #ffffff;
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
