@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap');

.abstract-submission-container {
  background-color: #f6f6e9;
  padding: 60px 40px; /* Increased padding for a more spacious layout */
  margin: 60px auto; /* Increased margin for better spacing on the page */
  max-width: 800px;
  border-radius: 15px; /* Softer rounded corners */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Softer, larger shadow */
  font-family: 'Roboto', sans-serif;
}

.abstract-title {
  text-align: center;
  color: #0d243d;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Playfair Display', serif; /* Elegant title font */
}

.submission-note {
  text-align: center;
  color: #ff6f61;
  margin-bottom: 30px;
  font-size: 1.1rem;
}

.abstract-form .form-group {
  margin-bottom: 30px;
}

.abstract-form label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1rem;
  color: #0d243d;
}

.abstract-form select,
.abstract-form input[type="text"],
.abstract-form input[type="file"] {
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px; /* Softer rounded corners */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.abstract-form select:focus,
.abstract-form input[type="text"]:focus,
.abstract-form input[type="file"]:focus {
  border-color: #00afef;
  box-shadow: 0 0 8px rgba(0, 175, 239, 0.2); /* Subtle glow on focus */
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 14px;
  font-size: 1.3rem;
  background: linear-gradient(135deg, #00afef, #0d243d); /* Gradient background */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  margin-top: 30px;
}

.submit-button:hover {
  background: linear-gradient(135deg, #0d243d, #00afef); /* Reversed gradient on hover */
  transform: translateY(-3px); /* Lift effect on hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .abstract-title {
    font-size: 2rem;
  }

  .abstract-form label {
    font-size: 1rem;
  }

  .submit-button {
    font-size: 1.2rem;
    padding: 12px;
  }
}

/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Guidelines and format section styling */
.guidelines-container {
  background-color: #f0f0f0;
  padding: 30px 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.guidelines-container h2 {
  font-size: 1.8rem;
  color: #0d243d;
  margin-bottom: 20px;
  font-family: 'Playfair Display', serif;
}

.guidelines-list, .format-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.guidelines-list li, .format-list li {
  margin-bottom: 10px;
  color: #333;
  font-size: 1rem;
}

.guidelines-list a {
  color: #00afef;
  text-decoration: none;
}

.guidelines-list a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {

  .guidelines-section {
    padding: 15px;
  }

  .guidelines-section h2 {
    font-size: 1.8rem;
  }

  .guidelines-section p {
    font-size: 1rem;
  }

  .guidelines-section h3 {
    font-size: 1.3rem;
  }
}

.file-note {
  font-size: 0.9rem;
  color: #ff6f61; /* Warning color */
  margin-top: 5px;
}
