.president-message-container {
  background: linear-gradient(135deg, #0d243d, #00afef);
  padding: 60px 20px;
  margin: 60px auto;
  max-width: 1200px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.president-title {
  text-align: center;
  color: #ffffff;
  font-size: 2.5rem;
  font-family: 'Playfair Display', serif;
  margin-bottom: 40px;
}

.president-message-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.president-image img {
  width: 200px;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-right: 40px;
}

.president-speech {
  color: #ffffff;
  font-size: 1.9rem;
  line-height: 1.5;
  max-width: 700px;
  text-align: justify;
}

.president-speech strong {
  font-size: 1.3rem;
}

.president-signature img {
  width: 150px;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .president-message-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .president-image img {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .president-speech {
    max-width: 100%;
  }

  .president-signature img {
    width: 100px;
  }
}
