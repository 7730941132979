.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-color: #ffffff; /* Background color to match your site's theme */
    /* background-color: #f6f6e9; */
    color: #0d243d;
}

.contact-us-title {
    text-align: center;
    color: #0d243d;
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: 700;
    font-family: 'Playfair Display', serif;
}

.coordinator-card {
    background: linear-gradient(135deg, #0d243d, #00afef);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 800px;
    color: #ffffff; /* White text on the gradient background */
}

.coordinator-info {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 15px;
}

.coordinator-info:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
}

.coordinator-name {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.coordinator-role {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.coordinator-mobile,
.coordinator-email {
    font-size: 1rem;
    margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-us-title {
        font-size: 1.8rem;
    }

    .coordinator-name {
        font-size: 1.3rem;
    }

    .coordinator-role {
        font-size: 1rem;
    }
}
