.qr-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-popup-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
  position: relative; /* Add this to position the close button */
}

.qr-popup-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #0d243d;
  transition: color 0.3s ease;
}

.qr-popup-close:hover {
  color: #00afef;
}

.qr-popup h5 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #0d243d;
  font-family: 'Playfair Display', serif;
}

.qr-image {
  width: 200px;
  height: 300px;
}

@media (max-width: 768px) {
  .qr-popup-content {
    padding: 20px;
  }

  .qr-image {
    width: 150px;
    height: 200px;
  }

  .qr-popup-close {
    font-size: 1.2rem;
    top: 10px;
    right: 10px;
  }
}
