.travel-partner-container {
  background-color: #fff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.travel-partner-title {
  font-size: 2.5rem;
  color: #0d243d;
  margin-bottom: 1.5rem;
}

.travel-partner-card h2 {
  color: white;
}

.travel-partner-card {
  background: linear-gradient(135deg, #0d243d, #00afef);
  border-radius: 20px;
  padding: 2rem;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  color: white;
  text-align: left;
}

.travel-partner-info p {
  display: flex;
  align-items: center;
  margin: 0.75rem 0;
  font-size: 1.1rem;
}

.icon-wrapper {
  margin-right: 0.5rem;
}

.travel-partner-card a {
  color: #f6f6e9;
  text-decoration: none;
}

.travel-partner-card a:hover {
  text-decoration: underline;
}
