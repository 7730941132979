.venue-container {
  padding: 20px;
  font-family: 'YourFontFamily', sans-serif;
  color: #333;
  background-color: #f6f6e9;
}

.venue-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.venue-info {
  width: 48%;
}

.venue-images {
  width: 48%;
  text-align: center;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.images-grid img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.images-grid img:hover {
  transform: scale(1.05);
}

h1 {
  text-align: center;
  color: #0d243d;
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
}

h2 {
  color: #00afef;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  margin-bottom: 10px;
}

a {
  color: #00afef;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

iframe {
  width: 100%;
  height: 400px;
  border: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 768px) {
  .venue-details {
    flex-direction: column;
  }

  .venue-info,
  .venue-images {
    width: 100%;
  }

  .images-grid {
    grid-template-columns: 1fr;
  }
}
